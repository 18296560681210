/* eslint-disable import/no-default-export */
import styled, { css } from "styled-components";

import { etBlack, etIceBlue, etWhite } from "../../shared/colors";
import { CardSize } from "../../shared/models/Card";

export namespace sc {
  const pTag = styled.p`
    margin: 0;
    padding: 0;
  `;
  const aTag = styled.a<{ color: string }>`
    color: ${(props) => props.color};
    :hover {
      color: ${(props) => props.color};
    }
  `;
  export const Container = styled.div<{
    cardSize: CardSize;
    align: string;
    color: string;
    bgImage?: string;
    bgColor: string;
  }>`
    position: relative;
    height: 496px;
    max-height: 496px;
    background-image: ${(props) =>
      props.bgImage ? `url(${props.bgImage})` : "none"};
    background-position: top center;
    background-size: cover;
    border-radius: 8px;
    overflow: hidden;
    padding: 12px;
    color: ${(props) => props.color};
    background-color: ${(props) => props.bgColor};
    ${(props) =>
      !props.bgImage &&
      props.bgColor === etIceBlue &&
      `border: 1px solid ${etBlack};`};

    .Button-Wrapper {
      ${(props) =>
        props.cardSize === CardSize.SINGLE
          ? `left: 24px;`
          : props.cardSize === CardSize.DOUBLE
          ? `left: 36px;`
          : `left: 60px;`};

      ${(props) => props.align === "CENTER" && `left: 0`};
    }
  `;
  export const CardLink = styled.a`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  `;
  export const CardOverLay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: ${etBlack};
    opacity: 0.6;
    z-index: 5;
  `;
  export const CardHeader = styled.div`
    position: relative;
    height: 20px;
    max-height: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 4;
  `;
  export const CardHeaderLeft = styled.div`
    display: flex;
    flex-direction: row;
  `;
  export const CategoryWrapper = styled.div`
    display: grid;
    place-items: center;
    width: 20px;
    max-width: 20px;
    height: 20px;
    max-height: 20px;
  `;
  export const Avatar = styled.img`
    width: 20px;
    max-width: 20px;
    height: 20px;
    max-height: 20px;
  `;
  export const DeckName = styled(aTag)`
    font-family: Roboto Condensed;
    font-size: 14px;
    font-weight: normal;
    margin-left: 6px;
    cursor: pointer;
  `;
  export const CardHeaderRight = styled.div``;
  export const ContextMenuWrapper = styled.div`
    position: absolute;
    right: 12px;
    top: 30px;
    z-index: 14;
  `;
  export const ContextMenuFullScreenLayer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 13;
    background-color: ${etBlack};
    opacity: 0;
    cursor: default;
  `;
  export const Article = styled.div<{
    textAlign: string;
  }>`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 2;
    width: 100%;
    height: calc(100% - 20px);
    text-align: ${(props) => props.textAlign};
    padding: 12px;
  `;
  export const TitleWrapper = styled.div`
    position: relative;
    height: 250px;
    max-height: 250px;
  `;
  export const ArticleTitle = styled.h1<{
    fontSize: string;
    lineHeight: string;
  }>`
    width: 100%;
    position: absolute;
    bottom: 0;
    font-family: Roboto;
    font-weight: 700;
    font-size: ${(props) => props.fontSize};
    letter-spacing: -0.5px;
    line-height: ${(props) => props.lineHeight};
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
  `;
  export const ArticleSubtitle = styled(pTag)<{
    fontSize: string;
    lineHeight: string;
  }>`
    margin-bottom: 20px;
    max-height: 90px;
    font-family: "Roboto Slab";
    font-weight: 400;
    font-size: ${(props) => props.fontSize};
    letter-spacing: 0px;
    line-height: ${(props) => props.lineHeight};
  `;
  export const ArticleSummary = styled(pTag)`
    margin-bottom: 20px;
    width: 100%;
    max-height: 90px;
    font-family: Roboto;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 20px;
  `;
  export const ButtonWrapper = styled.div<{ align: string }>`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: ${(props) => props.align};
    z-index: 4;
    ${(props) => props.align === "CENTER" && `left: 0`};
    a:hover {
      text-decoration: none;
    }
  `;
  export const Button = styled(aTag)<{
    shouldHaveBorder: boolean;
    color: string;
  }>`
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 20px;
    font-family: Roboto Condensed;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 0 20px;
    cursor: pointer;
    background-color: transparent;

    ${(props) =>
      props.shouldHaveBorder &&
      css`
        border: 1px solid ${props.color};
        color: ${props.color};
      `}

    :hover {
      text-decoration: none;
    }
  `;
}
