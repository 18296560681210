import { observer } from "mobx-react-lite";

import { etGray } from "../../shared/colors";
import {
  CardSize,
  CardTextAlignment,
  CardTextColor,
  CardType,
} from "../../shared/models/Card";
import { useTranslation } from "../../shared/translate/NextI18next";
import { ContextMenuCardDelete } from "../CardSimpleContext/ContextMenuCardDelete";
import ContextMenuCardEdit from "../CardSimpleContext/ContextMenuCardEdit";
import { ContextMenuCardOrder } from "../CardSimpleContext/ContextMenuCardOrder";
import { ContextMenuCardReport } from "../CardSimpleContext/ContextMenuCardReport";
import { IconSvg } from "../IconSvg/IconSvg";
import { sc } from "./CardItemEditableContextMenu.styled";

export interface CardItemEditableContextMenuDriver {
  isGuestView: boolean;
  isCardSizeVisible: boolean;
  contentType: CardType.EDITABLE;

  cardSize: CardSize;
  onCardSizeChanged(size: CardSize): void;

  textAlign: CardTextAlignment;
  onTextAlignmentChanged(textAlign: CardTextAlignment): void;

  textColor: CardTextColor;
  onTextColorChanged(textColor: CardTextColor): void;

  backgroundColor: string;
  onBackgroundColorChanged(value: string): void;

  shouldRenderCardOrder: boolean;
  cardOrderInput: string | null;
  sortNumberInputError: string | null;
  onCardOrderChanged(order: string): void;
  onCardOrderBlured(): void;
  onCardEditButtonClicked(): void;
  onCardDeleteButtonClicked(): void;
  onCardReportButtonClicked(): void;

  // This prop is using for storybook only
  hoveringItem?:
    | "CARD_SIZE"
    | "TEXT_ALIGN"
    | "TEXT_COLOR"
    | "BG_COLOR"
    | "MENU_ITEM"
    | "DELETE"
    | "REPORT";
}
interface IProps {
  driver: CardItemEditableContextMenuDriver;
}

export const CardItemEditableContextMenu = observer(
  function CardItemEditableContextMenu(props: IProps) {
    const {
      isGuestView,
      isCardSizeVisible,
      cardSize,
      onCardSizeChanged,
      shouldRenderCardOrder,
      cardOrderInput,
      sortNumberInputError,
      onCardOrderChanged,
      onCardOrderBlured,
      onCardEditButtonClicked,
      onCardDeleteButtonClicked,
      onCardReportButtonClicked,
      hoveringItem,
    } = props.driver;
    const { t } = useTranslation("CardItemInfo");

    return isGuestView ? (
      <sc.Container
        data-cy="CardItemEditableContextMenu-Container"
        className="visual-reg"
      >
        <sc.MenuItem data-cy="CardItemEditableContextMenu-MenuItemCardDelete">
          <ContextMenuCardReport
            onReport={onCardReportButtonClicked}
            isHovering={hoveringItem === "REPORT"}
          />
        </sc.MenuItem>
      </sc.Container>
    ) : (
      <sc.Container
        data-cy="CardItemEditableContextMenu-Container"
        className="visual-reg"
      >
        {isCardSizeVisible && (
          <sc.CardSizeContainer>
            <sc.BlockTitle data-cy="CardItemEditableContextMenu-CardSize">
              {t("context-menu.card-size.title")}
            </sc.BlockTitle>
            <sc.CardSizeItems>
              <sc.CardSizeItem
                data-cy="CardItemEditableContextMenu-CardSizeSingle"
                onClick={() => onCardSizeChanged(CardSize.SINGLE)}
              >
                <sc.IconBorder
                  shouldChangeIconColorWhenHover={true}
                  className={cardSize === CardSize.SINGLE ? `active` : ""}
                >
                  <IconSvg
                    icon="single-card"
                    width="32px"
                    height="32px"
                    padding="0"
                    color={etGray}
                    pointer
                  />
                </sc.IconBorder>
                <sc.CardSizeItemText>
                  {t("context-menu.card-size.single")}
                </sc.CardSizeItemText>
              </sc.CardSizeItem>
              <sc.CardSizeItem
                data-cy="CardItemEditableContextMenu-CardSizeDouble"
                onClick={() => onCardSizeChanged(CardSize.DOUBLE)}
              >
                <sc.IconBorder
                  shouldChangeIconColorWhenHover={true}
                  className={cardSize === CardSize.DOUBLE ? "active" : ""}
                >
                  <IconSvg
                    icon="double-card"
                    width="32px"
                    height="32px"
                    padding="0"
                    color={etGray}
                    pointer
                  />
                </sc.IconBorder>
                <sc.CardSizeItemText>
                  {t("context-menu.card-size.double")}
                </sc.CardSizeItemText>
              </sc.CardSizeItem>
              <sc.CardSizeItem
                data-cy="CardItemEditableContextMenu-CardSizeTriple"
                onClick={() => onCardSizeChanged(CardSize.TRIPLE)}
              >
                <sc.IconBorder
                  shouldChangeIconColorWhenHover={true}
                  className={
                    cardSize === CardSize.TRIPLE || hoveringItem === "CARD_SIZE"
                      ? `active`
                      : ""
                  }
                >
                  <IconSvg
                    icon="triple-card"
                    width="32px"
                    height="32px"
                    padding="0"
                    color={etGray}
                    fillColor={etGray}
                    pointer
                  />
                </sc.IconBorder>
                <sc.CardSizeItemText>
                  {t("context-menu.card-size.triple")}
                </sc.CardSizeItemText>
              </sc.CardSizeItem>
            </sc.CardSizeItems>
          </sc.CardSizeContainer>
        )}
        {shouldRenderCardOrder && (
          <sc.MenuItem
            data-cy="CardItemEditableContextMenu-MenuItemCardEdit"
            useDefaultCursor={true}
          >
            <ContextMenuCardOrder
              sortNumber={cardOrderInput || ""}
              onChanged={onCardOrderChanged}
              onBlured={onCardOrderBlured}
              sortNumberInputError={sortNumberInputError}
            />
          </sc.MenuItem>
        )}
        <sc.MenuItem data-cy="CardItemEditableContextMenu-MenuItemCardEdit">
          <ContextMenuCardEdit
            onEdit={onCardEditButtonClicked}
            isHovering={hoveringItem === "MENU_ITEM"}
          />
        </sc.MenuItem>
        <sc.MenuItem data-cy="CardItemEditableContextMenu-MenuItemCardDelete">
          <ContextMenuCardDelete
            onDelete={onCardDeleteButtonClicked}
            isHovering={hoveringItem === "DELETE"}
          />
        </sc.MenuItem>
      </sc.Container>
    );
  },
);
