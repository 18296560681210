/* eslint-disable unicorn/prefer-string-slice */
import { observer } from "mobx-react-lite";

import {
  etBlack,
  etBlue,
  etGreen,
  etIceBlue,
  etOrange,
  etPurpleUonCard,
  etRed,
  etWhite,
  etYellow,
} from "../../shared/colors";
import { useTranslation } from "../../shared/translate/NextI18next";
import { EtCircleColorPicker } from "../CardItemInfoContextMenu/EtCircleColorPicker";
import { sc } from "./ModalCreateCardInfo.styled";

export const INFO_CARD_ALLOWED_COLORS = [
  etRed,
  etOrange,
  etYellow,
  etGreen,
  etBlue,
  etPurpleUonCard,
  etIceBlue,
  etBlack,
  etWhite,
];

function isHexTooLight(color: string) {
  const hex = color.replace("#", "");
  const cR = Number.parseInt(hex.slice(0, Math.max(0, 0 + 2)), 16);
  const cG = Number.parseInt(hex.substring(2, 2 + 2), 16);
  const cB = Number.parseInt(hex.substring(4, 4 + 2), 16);
  const brightness = (cR * 299 + cG * 587 + cB * 114) / 1000;
  return brightness > 155;
}

export interface ModalCreateCardInfoBackgroundColorButtonDriver {
  backgroundColor: string;
  isBackgroundColorPickerVisible: boolean;
  onBackgroundColorButtonClicked(): void;
  onBackgroundColorChanged(hexColor: string): void;
  onBackgroundColorOverlayClicked(): void;
}

interface Props {
  driver: ModalCreateCardInfoBackgroundColorButtonDriver;
}

export const ModalCreateCardInfoBackgroundColorButton = observer(
  function ModalCreateCardInfoBackgroundColorButton(props: Props) {
    const { t } = useTranslation("ModalCreateCardInfo");

    const buttonTextColor = isHexTooLight(props.driver.backgroundColor)
      ? etBlack
      : etWhite;

    return (
      <>
        <sc.ControlContainer>
          <sc.ButtonBackgroundColor
            data-cy="ModalCreateCardInfo-BackgroundColorButton"
            backgroundColor={props.driver.backgroundColor}
            textColor={buttonTextColor}
            onClick={props.driver.onBackgroundColorButtonClicked}
          >
            {t("modalcreatecardinfo.card-background-color.placeholder")}
          </sc.ButtonBackgroundColor>
          {props.driver.isBackgroundColorPickerVisible && (
            <sc.BackgroundColorPickerContainer>
              <sc.BackgroundColorPickerPopover>
                <EtCircleColorPicker
                  color={props.driver.backgroundColor}
                  onChange={props.driver.onBackgroundColorChanged}
                />
              </sc.BackgroundColorPickerPopover>
            </sc.BackgroundColorPickerContainer>
          )}
          <sc.Note data-cy="ModalCreateCardInfo-BackgroundColorNote">
            {t("modalcreatecardinfo.card-background-color.note")}
          </sc.Note>
        </sc.ControlContainer>
        {props.driver.isBackgroundColorPickerVisible && (
          <sc.BackgroundColorPickerOverlay
            onClick={props.driver.onBackgroundColorOverlayClicked}
          />
        )}
      </>
    );
  },
);
