import { TextAlignment, TextColor } from "@earthtoday/contract";
import { observer } from "mobx-react-lite";
import Link from "next/link";
import { ReactNode } from "react";

import { CardSize, CardType } from "../../shared/models/Card";
import { CardActionTypeOfAction } from "../CardItemAction/CardItemAction";
import CardItemLayout, { LayoutDriver } from "../CardItemLayout/CardItemLayout";
import { IconSvg } from "../IconSvg/IconSvg";
import { sc } from "./CardItemEditable.styled";

export type ButtonLinkInterface =
  | {
      type: CardActionTypeOfAction.URL_INTERNAL;
      nextjsHrefPath: string;
      nextjsAsPath: string;
    }
  | {
      type: CardActionTypeOfAction.URL_EXTERNAL;
      nextjsHrefPath: string;
    };

export interface CardItemEditableDriver {
  contentType: CardType.EDITABLE;
  id: string;
  isEnableOpenCardView: boolean;
  categoryPrinciple: string;
  deckNameWithHashTag: string;
  deckPath: string;
  isContextMenuActive: boolean;
  onContextMenuEllipsisClicked(): void;
  onContextMenuFullscreenOverlayClicked(): void;

  headline: string;
  subhead: string;
  summary: string;
  backgroundImage: string;
  backgroundColor: string;
  textAlign: TextAlignment;
  textColor: TextColor;
  buttonText: string;
  buttonLinkDetails: ButtonLinkInterface | null;
}

interface IProps {
  driver: CardItemEditableDriver;
  layoutDriver: LayoutDriver;
  renderMenuContext(): ReactNode;
}

export const CardItemEditable = observer((props: IProps) => {
  const {
    id,
    isEnableOpenCardView,
    categoryPrinciple,
    deckNameWithHashTag,
    deckPath,
    textAlign,
    textColor,
    backgroundImage,
    backgroundColor,
    headline,
    subhead,
    summary,
    buttonText,
    buttonLinkDetails,
    isContextMenuActive,
    onContextMenuEllipsisClicked,
    onContextMenuFullscreenOverlayClicked,
  } = props.driver;
  const { cardSize } = props.layoutDriver;

  const titleFontSize =
    cardSize === CardSize.TRIPLE
      ? "50px"
      : cardSize === CardSize.DOUBLE
      ? "40px"
      : "30px";
  const titleLineHeight =
    cardSize === CardSize.TRIPLE
      ? "55px"
      : cardSize === CardSize.DOUBLE
      ? "45px"
      : "35px";

  const subtitleFontSize =
    cardSize === CardSize.TRIPLE
      ? "32px"
      : cardSize === CardSize.DOUBLE
      ? "24px"
      : "20px";
  const subtitleLineHeight =
    cardSize === CardSize.TRIPLE
      ? "37px"
      : cardSize === CardSize.DOUBLE
      ? "29px"
      : "25px";

  return (
    <CardItemLayout layoutDriver={props.layoutDriver}>
      <sc.Container
        data-cy="CardItemInfoCardFront-Container"
        cardSize={cardSize}
        color={textColor}
        bgImage={backgroundImage}
        align={textAlign}
        bgColor={backgroundColor}
      >
        {buttonLinkDetails &&
          buttonLinkDetails.type === CardActionTypeOfAction.URL_INTERNAL && (
            <Link
              href={buttonLinkDetails.nextjsHrefPath}
              as={buttonLinkDetails.nextjsAsPath}
              passHref
              legacyBehavior
            >
              <sc.CardLink />
            </Link>
          )}
        {buttonLinkDetails &&
          buttonLinkDetails.type === CardActionTypeOfAction.URL_EXTERNAL && (
            <Link
              href={buttonLinkDetails.nextjsHrefPath}
              passHref
              legacyBehavior
            >
              <sc.CardLink target="_blank" />
            </Link>
          )}
        {isContextMenuActive && (
          <>
            <sc.ContextMenuFullScreenLayer
              onClick={onContextMenuFullscreenOverlayClicked}
            />
            <sc.CardOverLay />
            <sc.ContextMenuWrapper>
              {props.renderMenuContext()}
            </sc.ContextMenuWrapper>
          </>
        )}
        {isEnableOpenCardView && (
          <Link
            href="/[vanityName]/[deckName]/[cardId]"
            as={`${deckPath}/${id}`}
            passHref
            legacyBehavior
          >
            <sc.CardLink data-cy="CardItemInfoCardFront-CardOverlay" />
          </Link>
        )}
        <sc.CardHeader data-cy="CardItemInfoCardFront-CardHeader">
          <sc.CardHeaderLeft>
            <sc.CategoryWrapper>
              <IconSvg
                data-cy="CardItemArticle-Icon"
                icon={`${categoryPrinciple.toLowerCase()}`}
                width="19px"
                height="18px"
                padding="0"
                color={textColor}
                fillColor={textColor}
              />
            </sc.CategoryWrapper>
            <Link
              href="/[vanityName]/[deckName]"
              as={deckPath}
              passHref
              legacyBehavior
            >
              <sc.DeckName
                data-cy="CardItemInfoCardFront-DeckName"
                color={textColor}
              >
                {deckNameWithHashTag}
              </sc.DeckName>
            </Link>
          </sc.CardHeaderLeft>
          <sc.CardHeaderRight
            data-cy="CardItemInfoCardFront-OpenContextMenuButton"
            onClick={onContextMenuEllipsisClicked}
          >
            <IconSvg
              icon="more"
              pointer
              width="20px"
              height="20px"
              padding="0"
              color={textColor}
            />
          </sc.CardHeaderRight>
        </sc.CardHeader>
        <sc.Article textAlign={textAlign} className="Article-Content">
          <sc.TitleWrapper>
            <sc.ArticleTitle
              data-cy="CardItemInfoCardFront-ArticleHeadline"
              fontSize={titleFontSize}
              lineHeight={titleLineHeight}
            >
              {headline}
            </sc.ArticleTitle>
          </sc.TitleWrapper>

          {!!subhead && (
            <sc.ArticleSubtitle
              data-cy="CardItemInfoCardFront-ArticleSubhead"
              fontSize={subtitleFontSize}
              lineHeight={subtitleLineHeight}
            >
              {subhead}
            </sc.ArticleSubtitle>
          )}
          {!!summary && (
            <sc.ArticleSummary data-cy="CardItemInfoCardFront-ArticleSummary">
              {summary}
            </sc.ArticleSummary>
          )}
          {!!buttonText && (
            <sc.ButtonWrapper align={textAlign}>
              <sc.Button color={textColor} shouldHaveBorder={true}>
                {buttonText}
              </sc.Button>
            </sc.ButtonWrapper>
          )}
        </sc.Article>
      </sc.Container>
    </CardItemLayout>
  );
});
