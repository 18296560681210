import styled, { css } from "styled-components";

import {
  etBlack,
  etBlueFaded,
  etBrightLightBlue,
  etGray,
  etIceBlue,
  etShadowDarkBlueFaded,
  etVeryMarshmallow,
  etWhite,
} from "../../shared/colors";

export namespace sc {
  const pTag = styled.p`
    margin: 0;
    padding: 0;
    color: ${etGray};
  `;
  export const Container = styled.div`
    padding: 8px 12px 12px 12px;
    width: 224px;
    max-width: 224px;
    background-color: ${etWhite};
    border-radius: 6px;
    box-shadow: 0px 16px 24px 0px rgba(42, 54, 66, 0.2);
  `;
  export const CardSizeContainer = styled.div`
    width: 100%;
  `;
  export const BlockTitle = styled.h4`
    margin-bottom: 7px;
    color: ${etGray};
    font-family: Roboto;
    font-weight: bold;
    font-size: 12px;
    height: 14px;
    letter-spacing: 0;
  `;
  export const CardSizeItems = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 10px;
  `;
  export const CardSizeItem = styled.div`
    cursor: pointer;
  `;
  export const IconBorder = styled.div<{
    shouldChangeIconColorWhenHover?: boolean;
  }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    padding: 0;
    border: 1px solid ${etIceBlue};
    border-radius: 4px;
    box-shadow: none;
    cursor: pointer;

    &:hover {
      background-color: ${etBlueFaded};
      border-color: ${etBrightLightBlue};
      box-shadow: 0 4px 16px 0 ${etShadowDarkBlueFaded};

      svg {
        fill: ${etBrightLightBlue};
        ${(props) =>
          !!props.shouldChangeIconColorWhenHover &&
          `path {
          fill: ${etBrightLightBlue};
        }`}
      }
    }
    &.active {
      background-color: ${etBlueFaded};
      border-color: ${etBrightLightBlue};
      box-shadow: 0 4px 16px 0 ${etShadowDarkBlueFaded};

      svg {
        fill: ${etBrightLightBlue};
        ${(props) =>
          !!props.shouldChangeIconColorWhenHover &&
          `path {
          fill: ${etBrightLightBlue};
        }`}
      }
    }
  `;
  export const CardSizeItemText = styled(pTag)`
    margin-top: 2px;
    width: 100%;
    text-align: center;
    font-family: Roboto Condensed;
    font-size: 10px;
    font-weight: normal;
    height: 11px;
    letter-spacing: 0;
    text-transform: uppercase;
  `;
  export const TextAlignment = styled.div`
    width: 89px;
    max-width: 89px;
  `;
  export const TextAlignmentItems = styled(CardSizeItems)`
    gap: 9px;
  `;
  export const MenuItem = styled.div<{ useDefaultCursor?: boolean }>`
    margin-top: 6px;
    cursor: ${(props) => (props.useDefaultCursor ? "default" : "pointer")};
    & > button {
      width: 200px;
    }
  `;
}
