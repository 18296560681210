import styled from "styled-components";

import { etBlue, etRegentGray, etWhite } from "../../shared/colors";

namespace sc {
  export const Container = styled.div<{ padding: string; margin: string }>`
    position: relative;
    width: fit-content;
    padding: ${(props) => props.padding};
    margin: ${(props) => props.margin};
  `;
  export const GrayButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${etRegentGray};
    border-radius: 20px;
    height: 32px;
    width: 32px;
    border: none;

    &:hover {
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
        0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    }

    :disabled {
      opacity: 0.5;
      cursor: default;

      & > div {
        cursor: default;
      }
    }
  `;
  export const WhiteButton = styled(GrayButton)`
    background-color: ${etWhite};
  `;
  export const BlueButton = styled(GrayButton)`
    background-color: ${etBlue};
  `;
}

export default sc;
